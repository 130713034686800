


































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import { AttachmentHostType, FundProjectCloseInput } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateEndReport extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundProjectCloseInput = {};
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.FundApply;
  }

  created() {
    this.form.projectId = Number(this.$route.params.id);
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.fundProject
          .close({
            body: this.form,
          })
          .then(() => {
            this.$router.back();
            this.$message.success("操作成功");
          });
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "标题必填",
        trigger: "blur",
      },
    ],
    summary: [
      {
        required: true,
        message: "简介必填",
        trigger: "blur",
      },
    ],
    content: [
      {
        required: true,
        message: "内容必填",
        trigger: "blur",
      },
    ],
  };
}
